<!-- eslint-disable vue/no-v-html -->
<template>
  <transition
    name="fade-top"
    appear
  >
    <section>
      <div class="head-options">
        <div
          class="back-btn"
          @click="backToCategory"
        >
          <v-icon>mdi-chevron-left</v-icon>
          <span>Back to Categories</span>
        </div>
        <div v-if="selectedFAQ.slug !== 'new'">
          <v-chip class="text-capitalize">
            {{ selectedFAQ.slug.replace(/_/g, " ") }}
          </v-chip>
        </div>
      </div>
      <div class="chat-container">
        <div class="chat-messages">
          <div
            v-for="(msg, index) in messages"
            :key="index"
            :class="['message', msg.sender]"
          >
            <div
              v-if="msg.isMarkdown"
              v-html="msg.text"
            />
            <div v-else>
              {{ msg.text }}
            </div>
          </div>
        </div>
        <div class="chat-input">
          <v-text-field
            v-model="input"
            type="text"
            outlined
            :disabled="loading"
            placeholder="Type your question here..."
            hide-details
            @keydown.enter="sendMessage"
          />
          <v-btn
            :disabled="loading"
            @click="sendMessage"
          >
            <v-icon> mdi-send</v-icon>
          </v-btn>
        </div>
      </div>
    </section>
  </transition>
</template>

<script>
import { mapGetters } from "vuex";
import showdown from "showdown";

const converter = new showdown.Converter();

export default {
  name: "ORKOChat",
  data() {
    return {
      loading: false,
      input: "",
      // input: "how many trips are there for the month of jan 2025",
      messages: [],
      loadingInterval: null,
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      list: "AIinsights/getList",
      selectedFAQ: "AIinsights/getSelectedFAQ",
    }),
  },
  mounted() {
    if (this.selectedFAQ?.id > 0) {
      const qry = this.selectedFAQ;
      const r = this.list.find((r) => r.slug == qry.slug);
      this.input = r.ask[qry.id - 1];
      this.sendMessage();
    }
  },
  methods: {
    async sendMessage() {
      if (!this.input.trim()) return;

      const userMsg = { sender: "user", text: this.input };
      const botMsg = { sender: "bot", text: "", isMarkdown: false };
      this.messages.push(userMsg, botMsg);
      const botMsgIndex = this.messages.length - 1;

      let dotCount = 0;
      this.loadingInterval = setInterval(() => {
        dotCount = (dotCount % 3) + 1;
        this.messages[botMsgIndex].text =
          "ORKO AI is typing" + ".".repeat(dotCount);
      }, 500);

      this.loading = true;
      const params = {
        question: this.input,
      };
      if (!this.authUser.isAdmin) {
        params.tenant_id = this.authUser.independent_fleet_user_nature
          ? this.authUser.tenant_id
          : this.authUser.parent_tenant_id;
      }
      this.$store.dispatch("AIinsights/chat", params).then((res) => {
        clearInterval(this.loadingInterval);
        this.simulateTypingEffect(botMsgIndex, res);
      });

      this.input = "";
    },
    simulateTypingEffect(msgIndex, fullText) {
      let currentIndex = 0;
      this.messages[msgIndex].text = "";
      this.messages[msgIndex].isMarkdown = true;
      const typingInterval = setInterval(() => {
        currentIndex++;
        const partialText = fullText.substring(0, currentIndex);
        this.messages[msgIndex].text = converter.makeHtml(partialText);
        if (currentIndex >= fullText.length) {
          clearInterval(typingInterval);
          this.messages[msgIndex].text = converter.makeHtml(fullText);
          this.messages[msgIndex].isMarkdown = true;
          this.loading = false;
        }
      }, 30);
    },
    //
    backToCategory() {
      this.$store.commit("AIinsights/SET_SELECTED_FAQ", {});
    },
  },
};
</script>

<style lang="sass" scoped>
.head-options
    display: flex
    align-items: center
    gap: 2rem
    margin-bottom: 1.5rem
    .back-btn
        cursor: pointer
        color: #4f46e5
        display: flex
        align-items: center
        padding: 1rem
        transition: all 0.2s ease
        i
            color : #4f46e5
        &:hover
            background-color : #eef2ff
    .v-chip
        color: #4f46e5
        background: #c7d2fe !important
        :deep .v-chip__content
            font-size: 12px
            font-weight: 600

.chat-container
    display: flex
    flex-direction: column
    background-color: #fff
    height: 80vh
    box-shadow: 2px 2px 30px 5px lightgray
    border-radius: 10px
    .chat-messages
        flex: 1
        display: flex
        flex-direction: column
        padding: 20px
        overflow-y: auto

        .message
            margin-bottom: 10px
            padding: 10px
            border-radius: 8px
            max-width: 70%
            word-wrap: break-word
        .message.user
            background-color: #00a5a0
            color: white
            align-self: flex-end

        .message.bot
            background-color: #e2e8f0
            color: #333
            align-self: flex-start
            :deep pre
              text-wrap: wrap !important


.chat-input
    display: flex
    padding: 10px
    gap: 0.5rem
    border-top: 1px solid lightgray
    background: #fff
    border-bottom-left-radius: 10px
    border-bottom-right-radius: 10px
    input
        flex: 1
        padding: 10px
        border: 1px solid #ccc
        border-radius: 8px
        outline: none
    .v-btn
        margin: 0
        height: 100%
        background-color: #4f46e5 !important
</style>
